/*
Theme Name: Varia

Adding print support. The print styles are based on the the great work of
Andreas Hecht in https://www.jotform.com/blog/css-perfect-print-stylesheet-98272/.
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Margins
# Typography
# Page breaks
# Links
# Visibility
--------------------------------------------------------------*/
@media print {
	/* Margins */
	@page {
		margin: 2cm;
	}
	.entry {
		margin-top: 1em;
	}
	.entry .entry-header, .site-footer .site-info {
		margin: 0;
	}
	/* Fonts */
	body {
		font: 13pt Georgia, "Times New Roman", Times, serif;
		font: 13pt var(--font-base, Georgia, "Times New Roman", Times, serif);
		line-height: 1.3;
		background: #fff !important;
		color: #000;
	}
	h1 {
		font-size: 24pt;
	}
	h2,
	h3,
	h4,
	.has-regular-font-size,
	.has-large-font-size,
	h2.author-title,
	p.author-bio,
	.comments-title, h3 {
		font-size: 14pt;
		margin-top: 25px;
	}
	/* Page breaks */
	a {
		page-break-inside: avoid;
	}
	blockquote {
		page-break-inside: avoid;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}
	img {
		page-break-inside: avoid;
		page-break-after: avoid;
	}
	table, pre {
		page-break-inside: avoid;
	}
	ul, ol, dl {
		page-break-before: avoid;
	}
	/* Links */
	a:link, a:visited, a {
		background: transparent;
		font-weight: bold;
		text-decoration: underline;
		text-align: left;
	}
	a {
		page-break-inside: avoid;
	}
	a[href^=http]:after {
		content: " < " attr(href) "> ";
	}
	a:after > img {
		content: "";
	}
	article a[href^="#"]:after {
		content: "";
	}
	a:not(:local-link):after {
		content: " < " attr(href) "> ";
	}
	/* Visibility */
	.main-navigation,
	.site-title + .main-navigation,
	.social-navigation,
	.site-branding-container:before,
	.entry .entry-title:before,
	.entry-footer,
	.author-description:before,
	.post-navigation,
	.widget-area,
	.comment-form-flex,
	.comment-reply,
	.comment .comment-metadata .edit-link {
		display: none;
	}
	.entry .entry-content .wp-block-button .wp-block-button__link,
	.entry .entry-content .button {
		color: #000;
		background: none;
	}
	/* Site Header (With Featured Image) */
	.site-header.featured-image {
		min-height: 0;
	}
	.site-header.featured-image .main-navigation a,
	.site-header.featured-image .main-navigation a + svg,
	.site-header.featured-image .social-navigation a,
	.site-header.featured-image .site-title a,
	.site-header.featured-image .site-featured-image a,
	.site-header.featured-image .site-branding .site-title,
	.site-header.featured-image .site-branding .site-description,
	.site-header.featured-image .main-navigation a:after,
	.site-header.featured-image .main-navigation .main-menu > li.menu-item-has-children:after,
	.site-header.featured-image .main-navigation li,
	.site-header.featured-image .social-navigation li,
	.site-header.featured-image .entry-meta,
	.site-header.featured-image .entry-title,
	.site-header.featured-image#masthead .site-title a {
		color: #000;
		text-shadow: none;
	}
	.site-header.featured-image .site-featured-image .entry-header,
	.site-header.featured-image .site-branding-container {
		margin-top: 0;
		margin-bottom: 0;
	}
	.site-header.featured-image .site-featured-image .post-thumbnail img {
		position: relative;
		height: initial;
		width: initial;
		object-fit: none;
		min-width: 0;
		min-height: 0;
		max-width: 100%;
		margin-top: 1rem;
	}
	/* Remove image filters from featured image */
	.image-filters-enabled *:after {
		display: none !important;
	}
	.image-filters-enabled .site-header.featured-image .site-featured-image:before {
		display: none;
	}
	.image-filters-enabled .site-header.featured-image .site-featured-image .post-thumbnail img {
		filter: none;
	}
}
