.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.site {
  background-color: #003a7c;
}

.headerBackground {
  background-size: cover;
  background-position: center;

  height: 240px;
  width: 100%;
  position: absolute;
  opacity: 0.3;
  top: 0;
}

#block-20 {
  width: 100%;
}

.wp-block-separator {
  border-bottom: 3px solid;
  border-top: none !important;
  margin-top: 4rem;
  margin-bottom: 4rem;
  border-color: #ccc !important;
  border-bottom: 2px solid #ccc !important;
}

#primary {
  width: fit-content;
  margin: auto;
}

.headerLines {
  position: relative;
  bottom: -20px;
}
.headerLines > svg {
  margin-bottom: -38px;
}

.marginAuto {
  margin: auto;
}

#page {
  margin-bottom: 40px;
}

.blockDisplay {
  display: block !important;
}

#wpfront-scroll-top-container {
  position: fixed;
  cursor: pointer;
  z-index: 9999;
  bottom: 30px;
  right: 30px;
  display: block !important;
}

.wp-block-column > p {
  text-align: justify;
}

.wp-block-separator {
  border-bottom: 1px solid #eee !important;
  border-color: #eee;
}

.right-float {
  float: right;
}

.headerLines > svg {
  fill: #fff;
  stroke: #fff;
  margin-bottom: -10px;
}

.site-branding {
  z-index: 1;
}

.menu-item > a {
  color: #fff !important;
  text-shadow: 1px 1px 7px #000;
}

.main-navigation {
  z-index: 2;
}

.publicationsTitle {
  font-size: 16px;
  line-height: 20px;
  opacity: 0.7;
  text-align: left;
  margin-bottom: 10px;
}

.shadowTop {
  background-image: linear-gradient(#000, transparent);
  position: absolute;
  height: 150px;
  top: 0;
  opacity: 0.7;
  width: 100%;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 400 !important;
  text-align: left;
}

.menu-item {
  font-weight: 400 !important;
}

.menu-item:hover {
  font-weight: 500 !important;
}

.roundedFlag {
  border-radius: 10px !important;
}

.current-menu-item {
  font-weight: 300 !important;
}

.margin24 {
  margin-top: 24px !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 560px) {
  .main-navigation > div {
    background-color: #145f3e !important;
  }
  .entry-content {
    padding: 20px;
  }
}
